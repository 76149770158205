import React from "react"
import { graphql } from "gatsby"

import Layout, { zDarkTheme } from "../../components/layout"
import HeadingCta from "../../components/headingCta"
import ZeusModules from "../../components/zeusModules"
import styles from "./success.module.scss"

export const query = graphql`
  query {
    craft {
      entry(section: "contactSuccess") {
        title
        seomatic {
          metaTagContainer
          metaLinkContainer
          metaTitleContainer
          metaJsonLdContainer
        }
        ... on Craft_contactSuccess_contactSuccess_Entry {
          alternateTitle
          body
          zeusModules {
            ...ZeusModules
          }
          headingCta {
            ...HeadingCta
          }
        }
      }
    }
  }
`

const ContactSuccessPage = ({ data, pageContext }) => {
  const entry = data.craft.entry
  const seo = entry.seomatic
  let title

  const theme = pageContext.theme || zDarkTheme;

  if (entry.alternateTitle) {
    title = entry.alternateTitle
  } else {
    title = entry.title
  }

  return (
    <Layout seo={seo} theme={theme}>
      <div className="page-content-module">
        <div className="wrapper">
          <div className="columns no-vertical-gap is-centered ">
            <div className="column is-8-desktop">
              <h1
                className="h-2 h--cms-title text-center mb-3"
                dangerouslySetInnerHTML={{
                  __html: title,
                }}
              ></h1>
            </div>
          </div>
          <div className={styles.body}>
            <div className="columns is-centered">
              <div className="column is-10-tablet is-6-desktop is-4-widescreen">
                <div
                  className="user-content text-center"
                  dangerouslySetInnerHTML={{
                    __html: entry.body,
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>
        <ZeusModules data={entry.zeusModules} />
        <HeadingCta data={entry.headingCta[0]} />
      </div>
    </Layout>
  )
}

export default ContactSuccessPage
